import React from 'react';

// Path to the logo file on your project
import SymphonyLogo from '../assets/symphony-logo.svg';

const Logo = () => (
  <img
    src={SymphonyLogo}
    alt="Salt Lake Acting Company logo"
    style={{ width: 180 }}
  />
);

export default Logo;
